import React, {
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect,
} from "react"
import PropTypes from "prop-types"
import debounce from "lodash-es/debounce"
import algoliaClient from "algoliasearch/lite"
import Link from "components/Link"
import theme from "styles/theme"
import { ReactComponent as PlusIcon } from "images/navPlus.svg"
import { ReactComponent as SearchIcon } from "images/icons/search.svg"
import CTAButton from "components/buttons/CTAButton"
import { AppContext } from "components/App"

const algolia = algoliaClient(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
).initIndex("pages")

const search = (query, params = {}) =>
  algolia.search(query, {
    attributesToHighlight: null,
    hitsPerPage: 60,
    ...params,
  })

const Search = ({ inverted, className }) => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [query, setQuery] = useState("")
  const inputRef = useRef()
  const { searchOpen, setSearchOpen } = useContext(AppContext)

  useEffect(() => {
    // Set focus on input when component mounts
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const updateResults = useCallback(
    debounce(currentQuery => {
      search(currentQuery).then(({ hits }) => {
        setResults(hits)
        setLoading(false)
      })
    }, 250),
    []
  )

  const handleChange = useCallback(
    e => {
      setQuery(e.target.value)

      if (e.target.value.length > 0) {
        setLoading(true)
        updateResults(e.target.value)
      } else {
        setLoading(false)
      }
    },
    [updateResults]
  )

  const closeSearch = useCallback(() => {
    setSearchOpen(false)
  }, [setSearchOpen])

  return (
    <div
      css={{
        maxWidth: 1140 + 80,
        padding: "100px 40px",
        margin: "0 auto",
        [theme.tablet]: {
          maxWidth: 620 + 80,
          padding: "85px 40px",
        },
        [theme.mobile]: {
          maxWidth: 300 + 80,
          padding: "70px 40px",
        },
      }}
      className={className}
    >
      <SearchHeader
        query={query}
        handleChange={handleChange}
        inverted={inverted}
        onNavigate={closeSearch}
        ref={inputRef}
      />

      {results.length > 0 ? (
        <Results pages={results} onNavigate={closeSearch} />
      ) : (
        query.length > 0 &&
        !loading && (
          <NoResults
            onCancel={() => {
              if (searchOpen) {
                closeSearch()
              } else {
                setQuery("")
                if (inputRef.current) {
                  inputRef.current.focus()
                }
              }
            }}
          />
        )
      )}
    </div>
  )
}

export default Search

Search.propTypes = {
  inverted: PropTypes.bool,
  className: PropTypes.string,
}

const SearchHeader = React.forwardRef(
  ({ query, handleChange, inverted, onNavigate }, ref) => (
    <div
      css={{
        textAlign: "center",
        margin: "0 auto 40px",
        maxWidth: 747,
        color: inverted ? theme.white : theme.textGray,
        "--highlightColor": inverted ? theme.lightOrange : theme.midBlue,
      }}
    >
      <div css={{ ...theme.t5lg, color: "var(--highlightColor)" }}>Search</div>
      <h1
        css={{
          ...theme.t3,
          marginTop: 11,
          marginBottom: 15,
        }}
      >
        Search Marathon Oil
      </h1>
      <div css={{ ...theme.bodyCopySM }}>
        Looking for SEC filings or News Releases?{" "}
        <Link
          href="/"
          css={{
            fontWeight: 500,
            textDecoration: "underline",
            transition: "color 250ms",
            ":hover": { color: "var(--highlightColor)" },
          }}
          onClick={onNavigate}
        >
          Click Here
        </Link>
        .
      </div>

      <input
        ref={ref}
        type="search"
        value={query}
        onChange={handleChange}
        css={theme.expandQueries({
          appearance: "none",
          display: "block",
          width: "100%",
          borderRadius: 0,
          border: 0,
          outline: 0,
          background: inverted ? theme.nearWhite : theme.white,
          color: theme.darkGray,
          padding: ["26px 23px", 16, 14],
          fontSize: [20, 17, 15],
          fontWeight: 500,
          letterSpacing: "-0.02em",
          boxShadow: theme.cardShadow,
          margin: "30px auto 0",
          "::placeholder": {
            color: theme.softGray,
          },
          "::-webkit-search-cancel-button": {
            display: "none",
          },
        })}
        placeholder="Start typing…"
      />
      <SearchIcon
        css={theme.expandQueries({
          float: "right",
          marginRight: [24, 16, 14],
          color: theme.darkGray,
          transform: [
            "translateY(-48px)",
            "translateY(-36px)",
            "translateY(-32px)",
          ],
          width: [23, 20, 17],
          [["path", "circle"]]: { strokeWidth: 0.8 },
        })}
      />
    </div>
  )
)
SearchHeader.displayName = "SearchHeader"

SearchHeader.propTypes = {
  query: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  inverted: PropTypes.bool,
  onNavigate: PropTypes.func,
}

const Results = ({ pages, onNavigate }) => (
  <div
    css={{
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: 38,
      [theme.tablet]: {
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 10,
      },
      [theme.mobile]: {
        gridTemplateColumns: "1fr",
      },
    }}
  >
    {pages.map(({ objectID, ...rest }) => (
      <ResultTile key={objectID} onClick={onNavigate} {...rest} />
    ))}
  </div>
)

Results.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      desc: PropTypes.string.isRequired,
      objectID: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onNavigate: PropTypes.func,
}

const ResultTile = ({ slug, label, title, desc, onClick }) => (
  <Link
    href={slug}
    css={theme.expandQueries({
      "--highlightColor": theme.midBlue,
      background: theme.white,
      borderBottom: `6px solid var(--highlightColor)`,
      boxShadow: theme.cardShadow,
      padding: [24, 22, 20],
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: [240, 215, 200],
      transition: "border-bottom-color 250ms, box-shadow 250ms",
      ":hover": {
        "--highlightColor": theme.deepBlue,
        boxShadow: " 4px 4px 16px rgba(0, 0, 0, 0.2)",
      },
    })}
    onClick={onClick}
  >
    <div>
      <div
        css={{
          ...theme.t5sm,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "var(--highlightColor)",
          transition: "color 250ms",
        }}
      >
        <div css={{ marginRight: 16 }}>{label}</div>
        <PlusIcon css={theme.expandQueries({ width: [19, 15, 14] })} />
      </div>
      <h3
        css={theme.expandQueries({
          ...theme.cardTitlesSM,
          marginTop: [8, 4, 6],
        })}
      >
        {title}
      </h3>
    </div>
    <div css={{ ...theme.imageCaption, marginTop: 24 }}>{desc}</div>
  </Link>
)

ResultTile.propTypes = {
  slug: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

const NoResults = ({ onCancel }) => (
  <div
    css={{
      background: theme.textGray,
      color: theme.white,
      textAlign: "center",
      padding: "45px 24px",
      borderBottom: `6px solid ${theme.darkGray}`,
      margin: "66px auto",
      maxWidth: 354,
    }}
  >
    <div css={{ ...theme.t4, marginBottom: 16 }}>
      Oops! No results match your search.
    </div>
    <div css={{ ...theme.imageCaption, marginBottom: 30 }}>
      Try entering a different search term. You can refine your results by
      typing different words or phrases.
    </div>
    <CTAButton colorScheme="light" size="sm" onClick={onCancel}>
      Cancel Search
    </CTAButton>
  </div>
)

NoResults.propTypes = {
  onCancel: PropTypes.func.isRequired,
}
